import { useEffect, useState } from "react";
import "./BlogArticlesHolderStyles.css";
import axios from "axios";

export default function BlogArticlesHolder() {
    const [articles , setArticles] = useState();
    const getArticles = () =>{
        axios.post(`${process.env.REACT_APP_API_LINK}/blog/article`, {"query":"all"})
        .then(res => {
            setArticles(res.data);
        })
        .catch(error => console.log(error));
    }
    useEffect(()=>{
        getArticles();
    },[])
    // const articles2 = [
    //     {
    //         "articleID": "1",
    //         "image": "../images/blog_article_1.png",
    //         "author": "Nuwan Lakshan",
    //         "date": "April 2, 2022",
    //         "topic": "What are Electric Motors and How Do They Work?",
    //         "description": "Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada. Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada."
    //     },
    //     {
    //         "articleID": "2",
    //         "image": "../images/blog_article_2.png",
    //         "author": "Nuwan Lakshan",
    //         "date": "April 2, 2022",
    //         "topic": "What are Electric Motors and How Do They Work?",
    //         "description": "Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada. Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada."
    //     },
    //     {
    //         "articleID": "3",
    //         "image": "../images/blog_article_3.png",
    //         "author": "Nuwan Lakshan",
    //         "date": "April 2, 2022",
    //         "topic": "What are Electric Motors and How Do They Work?",
    //         "description": "Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada. Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada."
    //     },
    //     {
    //         "articleID": "4",
    //         "image": "../images/blog_article_4.png",
    //         "author": "Nuwan Lakshan",
    //         "date": "April 2, 2022",
    //         "topic": "What are Electric Motors and How Do They Work?",
    //         "description": "Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada. Pellentesque vehicula eros neque, maximus mattis est sagittis Nulla facilisi. In sed pretium metus. Proin pretium id urna sit amet tincidunt. Interdum et malesuada."
    //     },
    // ]
    return (
        <>
            <div className="articles-container">
                <div className="articles-inner">
                    <div className="sub-topic">
                        <img src='../images/home_service_logo_icon.svg' alt="" />
                        <p>Blog & Articles</p>
                    </div>
                    <h1 className="main-topic">Latest News and Articles</h1>
                    <div className="articles">
                        {
                            articles && articles.map((det, index) => {
                                return (
                                    <div className="article" key={index}>
                                        <div className="article-image">
                                            <img src={process.env.REACT_APP_API_LINK + det.image} alt="" />

                                        </div>
                                        <div className="article-subdet">
                                            <div className="side author">
                                                <h4><i className="fas fa-user"></i> {det.author}</h4>
                                            </div>
                                            <div className="side date">
                                                <h4><i className="fas fa-clock"></i> {det.date}</h4>
                                            </div>
                                        </div>
                                        <h2 className="article-topic">{det.topic}</h2>
                                        <div className="article-descrip">
                                            <p>{det.description}</p>
                                        </div>
                                        <a href={"/blog/article?articleID="+det.articleID} className="read-more">Read More<i className='fas fa-angle-double-right'></i></a>
                                    </div>
                                )
                            })
                        }                        
                    </div>
                    {/* <a href="/blog" className="nxt-btn"> Next </a> */}
                </div>
            </div>

        </>
    )
}