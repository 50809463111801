import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SettingsTabpane from "../components/SettingsTabpane";

export default function Settings() {
    const [userData, setUserData] = useState('');
    const navigate = useNavigate();
    axios.defaults.withCredentials = true;
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_LINK + '/validate')
            .then(res => {
                if (res.data.valid) {
                    setUserData(res.data);
                } else {
                    navigate('/login');
                }
            })
            .catch(err => console.log(err));
    }, [navigate]);

    return (
        <>
            <SettingsTabpane userDetails={userData}/>
        </>
    )
}