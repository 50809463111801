import "./NavbarStyles.css";
import React, { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function NavBar(props) {
    const [navIconClass, setNavIconClass] = useState(false);
    const [ifNavBarMobile, setIfNavBarMobile] = useState(false);
    const [ifScrollNavBar, setIfScrollNavBar] = useState(false);

    const mobileMenuOpenClose = () => {
        setNavIconClass(!navIconClass)
        setIfNavBarMobile(!ifNavBarMobile)
    };

    const scrollNavBar = () => setIfScrollNavBar((window.scrollY > 50) ? true : false);

    window.addEventListener('scroll', scrollNavBar);

    const clickOnLink = () => {
        setIfNavBarMobile(false);
        setNavIconClass(false);
    }

    return (
        <>
            <div className="loading-gif-container"> <img src="../images/loading.gif" alt="" /> </div>
            <nav className={ifScrollNavBar ? 'nav-container to-top' : 'nav-container'}>
                <div className={ifNavBarMobile ? 'nav-bar show' : 'nav-bar'}>
                    <div className="company-logo">
                        <img src='../images/de_logo.png' alt="DE Lanka logo" />
                    </div>
                    <div className="nav-close"><i className={navIconClass ? 'fas fa-times' : 'fas fa-bars'} onClick={mobileMenuOpenClose}></i></div>
                    <ul>
                        <li onClick={clickOnLink}><CustomLink to='/' >Home</CustomLink></li>
                        <li onClick={clickOnLink}><CustomLink to='/about' >About Us</CustomLink></li>
                        <li onClick={clickOnLink}><CustomLink to='/services' >Services</CustomLink></li>
                        <li onClick={clickOnLink}><CustomLink to='/blog' >Blog</CustomLink></li>
                        {props.userData && <li onClick={clickOnLink}><CustomLink to='/settings' >Settings</CustomLink></li>}
                        <li onClick={clickOnLink}><CustomLink to='/contact' >Contact Us</CustomLink></li>
                    </ul>
                    <Link to="/contact" className="nav-btn"> Have Any Questions? </Link>
                </div>
            </nav>
        </>
    )
}


function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    const ifContact = to === '/contact' ? ' contact' : '';
    return (
        <Link to={to} {...props} className={(isActive ? 'selected' : '') + ifContact}>{children}</Link>
    )
}


export default NavBar;