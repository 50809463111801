import './TabHomeStyles.css'
import TabHomeHero from './TabHomeHero';
import TabHomeAbout from './TabHomeAbout';
import TabHomeServices from './TabHomeServices';
import TabHomeWhyChoose from './TabHomeWhyChoose';

export default function TabHome() {

    return (
        <>
            <TabHomeHero />
            <TabHomeAbout />
            <TabHomeServices />
            <TabHomeWhyChoose />
        </>
    )
}