import ContactDetails from "../components/ContactDetails";
import ContactForm from "../components/ContactForm";
import ContactMap from "../components/ContactMap";

export default function Contact(props){
    return (
        <>
            <ContactDetails branchDetails={props.branchDetails} />
            <ContactMap />
            <ContactForm branchDetails={props.branchDetails} />
        </>
    )
}