import React, { useEffect, useState } from "react";
import "./LoginFormStyles.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function LoginForm(){
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        username : '',
        password : ''
    });

    useEffect(()=>{
        axios.get(process.env.REACT_APP_API_LINK+'/validate')
        .then(res => {
            if(res.data.valid){
                navigate('/settings')
            }else{
                navigate('/login');
            }
        })
        .catch(err => console.log(err));
    },[navigate]);
    const handleInput = (event) => {
        setInputValues(prev => ({...prev, [event.target.name]:[event.target.value]}));
    }
    axios.defaults.withCredentials = true;
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(process.env.REACT_APP_API_LINK+'/login', inputValues)
        .then(res => {
            if(res.data.Login){
                navigate('/settings');
            }else{
                alert('Access denied!');
            }
        })
        .catch(err => console.log(err));

    }
    
    const navigateTo = (event) => {
        event.preventDefault();    
        navigate('/register');
    }

    return (
        <>
            <div className="log-form-container">
                <div className="log-form-inner">
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <label htmlFor="username" ><strong>Username</strong></label>
                            <input type="text" name="username" placeholder="Enter Username" onChange={handleInput} />
                        </div>
                        <div className="field">
                            <label htmlFor="password" ><strong>Password</strong></label>
                            <input type="password" name="password" placeholder="Enter Password" onChange={handleInput}/>
                        </div>
                        <button type="submit" >Log in</button>
                        <a href="/" onClick={navigateTo}>Register</a>
                    </form>
                </div>
            </div>
        </>
    )
}