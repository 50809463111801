import "./ArticleInnerStyles.css";
import ImageSlider from "./ArticleImages";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export default function ArticleInner() {
    const [searchParams ] = useSearchParams();
    const [article , setArticles] = useState();
    const getArticles = () =>{
        axios.post(`${process.env.REACT_APP_API_LINK}/blog/article`, {"query": searchParams.get('articleID')})
        .then(res => {
            setArticles(res.data[0]);
        })
        .catch(error => console.log(error));
    }
    useEffect(()=>{
        getArticles();
        // eslint-disable-next-line
    },[])
    
    // const article2 = {
    //     "topic":"What are Electric Motors and How Do They Work?",
    //     "image":"../images/blog_article_1.png",
    //     "author":"Nuwan Lakshan",
    //     "date":"April 2, 2022",
    //     "paragraph1":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi illo dolorem quidem quis inventore, beatae repellendus atque expedita est praesentium quas nam autem similique ullam. Modi cupiditate veritatis officiis fugit? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus voluptate cupiditate voluptates iure, ducimus nulla quaerat magnam, obcaecati error omnis itaque quia ipsa labore vel, aut iste ex adipisci sunt. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores neque nam voluptatem natus ipsa porro sint aliquam, debitis maxime reprehenderit odit quos dolorum repellat est dicta, dolores suscipit iste quod! Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime dicta quis ratione velit fugit ad amet voluptate! Facilis vel laudantium reprehenderit perferendis magnam ullam molestiae dicta aspernatur nostrum unde.",
    //     "paragraph2":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi illo dolorem quidem quis inventore, beatae repellendus atque expedita est praesentium quas nam autem similique ullam. Modi cupiditate veritatis officiis fugit? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus voluptate cupiditate voluptates iure, ducimus nulla quaerat magnam, obcaecati error omnis itaque quia ipsa labore vel, aut iste ex adipisci sunt. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores neque nam voluptatem natus ipsa porro sint aliquam, debitis maxime reprehenderit odit quos dolorum repellat est dicta, dolores suscipit iste quod! Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime dicta quis ratione velit fugit ad amet voluptate! Facilis vel laudantium reprehenderit perferendis magnam ullam molestiae dicta aspernatur nostrum unde.",
    //     "paragraph3":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi illo dolorem quidem quis inventore, beatae repellendus atque expedita est praesentium quas nam autem similique ullam. Modi cupiditate veritatis officiis fugit? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus voluptate cupiditate voluptates iure, ducimus nulla quaerat magnam, obcaecati error omnis itaque quia ipsa labore vel, aut iste ex adipisci sunt. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores neque nam voluptatem natus ipsa porro sint aliquam, debitis maxime reprehenderit odit quos dolorum repellat est dicta, dolores suscipit iste quod! Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime dicta quis ratione velit fugit ad amet voluptate! Facilis vel laudantium reprehenderit perferendis magnam ullam molestiae dicta aspernatur nostrum unde.",
    //     "paragraph4":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi illo dolorem quidem quis inventore, beatae repellendus atque expedita est praesentium quas nam autem similique ullam. Modi cupiditate veritatis officiis fugit? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloribus voluptate cupiditate voluptates iure, ducimus nulla quaerat magnam, obcaecati error omnis itaque quia ipsa labore vel, aut iste ex adipisci sunt. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores neque nam voluptatem natus ipsa porro sint aliquam, debitis maxime reprehenderit odit quos dolorum repellat est dicta, dolores suscipit iste quod! Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus maxime dicta quis ratione velit fugit ad amet voluptate! Facilis vel laudantium reprehenderit perferendis magnam ullam molestiae dicta aspernatur nostrum unde.",
    //     "imageGallery":["../images/blog_article_1.png","../images/blog_article_2.png","../images/blog_article_3.png","../images/blog_article_4.png"]
    // }
    return (
        <>
            <div className="article-container">
                <div className="article-inner">
                    <h1>{article && article.topic}</h1>
                    <div className="article-image">
                        <img src={article && process.env.REACT_APP_API_LINK + article.image} alt="" />
                    </div>
                    <div className="article-subdet">
                        <div className="side author">
                            <h4><i className="fas fa-user"></i> {article && article.author}</h4>
                        </div>
                        <div className="side date">
                            <h4><i className="fas fa-clock"></i> {article && article.date}</h4>
                        </div>
                    </div>
                    <p>{article && article.paragraph1}</p>
                    <p>{article && article.paragraph2}</p>
                    <p>{article && article.paragraph3}</p>
                    <p>{article && article.paragraph4}</p>

                    {article &&  <ImageSlider imageGallery={JSON.parse(article.imageGallery)}/>}
                </div>
            </div>
        </>
    )
}