import "./ContactFormStyles.css"
import { useRef } from "react";
import emailjs from '@emailjs/browser';

export default function ContactForm(props) {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        
        let serviceID , templateID , apiKey;

        serviceID = 'service_s1f8cfv';
        templateID = 'template_ycw17vy';
        apiKey = 'F_95M6FOxvfcLb0eW';

        emailjs.sendForm(
            serviceID,
            templateID,
            form.current,
            apiKey
            )
            .then((result) => {
                alert('Your message successfully sent!')
                window.location.reload();
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <>
            <div className="form-container">
                <div className="form-inner">
                    <div className="sub-topic">
                        <img src='../images/home_service_logo_icon.svg' alt="" />
                        <p>Contact Us</p>
                    </div>
                    <h1 className="main-topic">Get In Touch</h1>
                    <form ref={form} onSubmit={sendEmail}>
                        <input type="text" className="first-inputs" name="user_name" placeholder="Your Name" required />
                        <input type="text" className="first-inputs" name="user_email" placeholder="Your Email" required />
                        <input type="text" className="first-inputs" name="user_phone" placeholder="Phone Number" required />
                        <input type="text" className="first-inputs" name="user_address" placeholder="Address" required />
                        <input type="text" className="subject-input" name="user_subject" placeholder="Subject" required />
                        <textarea id="" cols="30" rows="5" name="user_message" placeholder="Your Message" required>
                        </textarea>
                        <div className="button-holder">
                            <select className="sub-btn select" name="send_to">
                                {
                                    props.branchDetails && props.branchDetails.map((data,index)=>{
                                        return (
                                            <option key={index} value={data.email}>{data.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <input type="submit" className="sub-btn" value="Send" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}