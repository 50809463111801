import "./HeroOtherStyles.css";
import HeroTopSection from "./HeroTopSection";

export default function HeroOther(props) {
    return (
        <>
            <div className="hero-section">
                <HeroTopSection branchDetails={props.branchDetails}/>
                <div className="bottom">
                    <div className="hero-image">
                        <img src={props.heroImage} alt="" />
                    </div>
                    <div className="hero-title">
                        <h1>{props.heroTitle}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}