import { useEffect, useState } from 'react';
import axios from 'axios';

export default function TabHomeAbout() {
    const [aboutContent, setAboutContent] = useState();
    const getHomeAbout = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-home-about')
            .then(res => {
                setAboutContent(res.data);
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        getHomeAbout();
    }, [])

    const updateAboutTopic = () => {
        const aboutTopic = document.querySelector('#aboutTopic').value;
        if (aboutTopic.length === 0) {
            alert('Please enter a valid title to update.');
            return;
        } else if (aboutTopic.length > 30) {
            alert('Topic should be lover than 30 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update topic?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-home-about', { "contentType": "topic", "contentData": aboutTopic })
            .then(res => {
                getHomeAbout();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateAbotDescription = () => {
        const aboutDescription = document.querySelector('#aboutDescription').value;
        if (aboutDescription.length === 0) {
            alert('Please enter a valid title to update.');
            return;
        } else if (aboutDescription.length > 600) {
            alert('Hero title should be lover than 600 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero title?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-home-about', { "contentType": "description", "contentData": aboutDescription })
            .then(res => {
                getHomeAbout();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateAbotKeypoints = () => {
        const aboutKeypoints = document.querySelector('#aboutKeypoints').value;
        if (aboutKeypoints.length === 0) {
            alert('Please enter a valid title to update.');
            return;
        } else if (aboutKeypoints.length > 600) {
            alert('Hero title should be lover than 600 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero title?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-home-about', { "contentType": "keypoints", "contentData": aboutKeypoints })
            .then(res => {
                getHomeAbout();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const [selectedAboutImage, setSelectedAboutImage] = useState();
    const handleImage = (e) => {
        setSelectedAboutImage(e.target.files[0]);
        // const reader = new FileReader();
        // reader.readAsDataURL(selectedAboutImage);
        // reader.onload = (e) => {
        //   const image = new Image();
        //   image.src = e.target.result;
        //   image.onload = () => {
        //     const {
        //       height,
        //       width
        //     } = image;
        //     alert(width + ' * ' +height);
        //     // if (height > 100 || width > 100) {
        //     //   alert("Height and Width must not exceed 100px.");
        //     //   return false;
        //     // }
        //     // alert("Uploaded image has valid Height and Width.");
        //     // return true;
        //   };
        // };                
    }

    const updateAboutImage = (currentImage) => {
        if (!selectedAboutImage) {
            alert('Please select an image to update.');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero image?') === false) return;
        const formdata = new FormData();
        formdata.append('file', selectedAboutImage);
        formdata.append('deleteFile', '.' + currentImage);

        axios.post(process.env.REACT_APP_API_LINK + '/upload-image', formdata, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                axios.post(process.env.REACT_APP_API_LINK + '/content/update-home-about', { "contentType": "image", "contentData": '/images/' + selectedAboutImage.name })
                    .then(res => {
                        getHomeAbout();
                        alert('Successfully updated!');
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            <div className="tab-home--container">
                <h3 className='home-topics'>About Common</h3>
                <div className="inp-container">
                    <div className="det-row">
                        <label>Image</label>
                        <a href={aboutContent && process.env.REACT_APP_API_LINK + aboutContent.image} rel="noreferrer" target="_blank">Preview</a>
                        <input type="file" name='file' accept="image/png, image/jpeg" onChange={handleImage} />
                        <button onClick={() => updateAboutImage(aboutContent.image)}>Update</button>
                    </div>
                    <div className="det-row">
                        <label>Topic</label>
                        <input id='aboutTopic' type="text" defaultValue={aboutContent && aboutContent.topic} />
                        <button onClick={() => updateAboutTopic()}>Update</button>
                    </div>
                    <div className="det-row">
                        <label>Description</label>
                        <textarea name="" id="aboutDescription" cols="48" rows="10" defaultValue={aboutContent && aboutContent.description}></textarea>
                        <button onClick={() => updateAbotDescription()}>Update</button>
                    </div>
                    <div className="det-row">
                        <label>Keypoints</label>
                        <textarea name="" id='aboutKeypoints' cols="48" rows="2" defaultValue={aboutContent && aboutContent.keypoints}></textarea>
                        <button onClick={() => updateAbotKeypoints()}>Update</button>
                    </div>
                    <span className='guidline'>* Keypoints should separate by comma( , ) symbol.</span>
                </div>
            </div>
        </>
    )
}