import { useEffect, useState } from 'react';
import axios from 'axios';

export default function TabHomeHero(){
    const [heroContent, setHeroContent] = useState();
    const getHeroHome = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-hero-home')
            .then(res => {
                setHeroContent(res.data);
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        getHeroHome();
    }, [])

    const updateMiniTitle = () => {
        const miniTitle = document.querySelector('#miniTitle').value;
        if (miniTitle.length === 0) {
            alert('Please enter a valid title to update.');
            return;
        } else if (miniTitle.length > 60) {
            alert('Mini title should be lover than 60 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update mini title?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-hero-home', { "contentType": "mini_title", "contentData": miniTitle })
            .then(res => {
                getHeroHome();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateHeroTitle = () => {
        const heroTitle = document.querySelector('#heroTitle').value;
        if (heroTitle.length === 0) {
            alert('Please enter a valid title to update.');
            return;
        } else if (heroTitle.length > 65) {
            alert('Hero title should be lover than 65 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero title?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-hero-home', { "contentType": "hero_title", "contentData": heroTitle })
            .then(res => {
                getHeroHome();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const [selectedImage, setSelectedImage] = useState();
    const handleImage = (e) => {
        setSelectedImage(e.target.files[0]);
        // const reader = new FileReader();
        // reader.readAsDataURL(selectedImage);
        // reader.onload = (e) => {
        //   const image = new Image();
        //   image.src = e.target.result;
        //   image.onload = () => {
        //     const {
        //       height,
        //       width
        //     } = image;
        //     alert(width + ' * ' +height);
        //     // if (height > 100 || width > 100) {
        //     //   alert("Height and Width must not exceed 100px.");
        //     //   return false;
        //     // }
        //     // alert("Uploaded image has valid Height and Width.");
        //     // return true;
        //   };
        // };                
    }

    const updateHeroImage = (currentImage) => {
        if(!selectedImage){
            alert('Please select an image to update.');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero image?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        const formdata = new FormData();
        formdata.append('file', selectedImage);
        formdata.append('deleteFile', '.'+currentImage);

        axios.post(process.env.REACT_APP_API_LINK + '/upload-image', formdata, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                axios.post(process.env.REACT_APP_API_LINK + '/content/update-hero-home', { "contentType": "image", "contentData": '/images/'+selectedImage.name })
                .then(res => {
                    getHeroHome();
                    alert('Successfully updated!');
                    document.querySelector('.loading-gif-container').style.display = "none";
                })
                .catch(error => console.log(error))
            })
            .catch(error => console.log(error));
    }    
    return(
        <>
            <div className="tab-home--container">
                <h3 className='home-topics'>Hero Section</h3>
                <div className="inp-container">
                    <div className="det-row">
                        <label>Image</label>
                        <a href={heroContent && process.env.REACT_APP_API_LINK + heroContent.image} rel="noreferrer" target="_blank">Preview</a>
                        <input id='uploadImage' type="file" name='file' accept="image/png, image/jpeg" onChange={handleImage} />
                        <button onClick={() => updateHeroImage(heroContent.image)}>Update</button>
                    </div>
                    <div className="det-row">
                        <label>Mini Title</label>
                        <input id='miniTitle' type="text" defaultValue={heroContent && heroContent.mini_title} />
                        <button onClick={() => updateMiniTitle()}>Update</button>
                    </div>
                    <div className="det-row">
                        <label>Hero Title</label>
                        <input id='heroTitle' type="text" defaultValue={heroContent && heroContent.hero_title} />
                        <button onClick={() => updateHeroTitle()}>Update</button>
                    </div>
                </div>
            </div>        
        </>
    )    
}