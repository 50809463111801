import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function ImageSlider(props) {
    // const ArticleImage1 = '../images/blog_article_1.png'
    return (
        <div style={{ width: "100%", backgroundColor: "#fff" }}>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={10}
                slidesPerView={window.screen.width > 500 ? 2 : 1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
            >
                {
                    props.imageGallery.map((image, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <img src={process.env.REACT_APP_API_LINK+ image} alt="slide1" style={{ width: "100%" }} />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    );
}