import { useEffect, useState } from 'react';
import './TabBlogStyles.css';
import axios from 'axios';

export default function TabBlog() {
    const [articles, setArticles] = useState();
    const [articleAllImages, setArticleAllImages] = useState();
    const getArticles = () => {
        axios.post(`${process.env.REACT_APP_API_LINK}/blog/article`, { "query": "settings" })
            .then(res => {
                setArticles(res.data);
            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getArticles();
    }, [])

    const [editID, setEditID] = useState(0);

    const [blogDetails, setBlogDetails] = useState({ author: '', topic: '', descrip: '', para1: '', para2: '', para3: '', para4: '' });

    const handleInput = (event) => {
        setBlogDetails(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleEdit = (articleID, author, topic, descrip, para1, para2, para3, para4, allImages) => {
        setArticleAllImages(allImages);
        setBlogDetails({ author: author, topic: topic, descrip: descrip, para1: para1, para2: para2, para3: para3, para4: para4 });
        setEditID(articleID);
    }

    const cancelEdit = () => {
        setEditID(0);
        setBlogDetails({ author: '', topic: '', descrip: '', para1: '', para2: '', para3: '', para4: '' })
        document.querySelector('#mainImageInput').value = null;
        document.querySelector('#galleryImagesInput').value = null;
    }

    const createArticle = () => {
        const mainImage = document.querySelector('#mainImageInput').files[0];
        if (mainImage === undefined) {
            alert('Please select main image!');
            return;
        } else if (!blogDetails) {
            alert('Please try again later!');
            return
        } else if (blogDetails.author.length === 0) {
            alert('Please enter author name!');
            return
        } else if (blogDetails.author.length > 15) {
            alert('Author name should less than 15 characters!');
            return
        } else if (blogDetails.topic.length === 0) {
            alert('Please enter topic!');
            return
        } else if (blogDetails.topic.length > 50) {
            alert('Topic should less than 50 characters!');
            return
        } else if (blogDetails.descrip.length === 0) {
            alert('Please enter description!');
            return
        } else if (blogDetails.descrip.length >= 300) {
            alert('Description should less than 300 characters!');
            return
        } else if ((blogDetails.para1.length === 0) && (blogDetails.para2.length === 0) && (blogDetails.para3.length === 0) && (blogDetails.para4.length === 0)) {
            alert('Please enter at least a one paragraph.');
            return
        } else {
            const galleryImages = document.querySelector('#galleryImagesInput').files;
            const articleImageGallery = [];
            Array.from(galleryImages).forEach(element => {
                articleImageGallery.push(`/images/blog/${element.name}`)
            });
            const reqBody = {
                "articleAuthor": blogDetails.author,
                "articleImage": `/images/blog/${mainImage.name}`,
                "articleTopic": blogDetails.topic,
                "articleDescrip": blogDetails.descrip,
                "articlePara1": blogDetails.para1,
                "articlePara2": blogDetails.para2,
                "articlePara3": blogDetails.para3,
                "articlePara4": blogDetails.para4,
                "articleImageGallery": JSON.stringify(articleImageGallery)
            };

            // eslint-disable-next-line no-restricted-globals
            if (confirm('Do you want to create this blog article?') === false) return;
            document.querySelector('.loading-gif-container').style.display = "flex";
            axios.post(`${process.env.REACT_APP_API_LINK}/blog/create-article`, reqBody)
                .then(res => {
                    const newID = res.data[0].articleID;
                    const formdata = new FormData();
                    formdata.append('file', mainImage);
                    Array.from(galleryImages).forEach(element => {
                        formdata.append('file', element);
                    });
                    formdata.append('articleID', newID);

                    axios.post(process.env.REACT_APP_API_LINK + '/upload-blog-image', formdata, {
                        headers: { "Content-Type": "multipart/form-data" }
                    })
                        .then(res => {
                            alert('Successfully created!');
                            cancelEdit();
                            getArticles();
                            document.querySelector('.loading-gif-container').style.display = "none";
                        })
                        .catch(error => console.log(error));

                })
                .catch(error => console.log(error))
            // alert('OK');
        }
    }

    const updateArticle = () => {

        if (!blogDetails) {
            alert('Please try again later!');
            return
        }  else if (blogDetails.author.length === 0) {
            alert('Please enter author name!');
            return
        } else if (blogDetails.author.length > 15) {
            alert('Author name should less than 15 characters!');
            return
        } else if (blogDetails.topic.length === 0) {
            alert('Please enter topic!');
            return
        } else if (blogDetails.topic.length > 50) {
            alert('Topic should less than 50 characters!');
            return
        } else if (blogDetails.descrip.length === 0) {
            alert('Please enter description!');
            return
        } else if (blogDetails.descrip.length >= 300) {
            alert('Description should less than 300 characters!');
            return
        }  else if ((blogDetails.para1.length === 0) && (blogDetails.para2.length === 0) && (blogDetails.para3.length === 0) && (blogDetails.para4.length === 0)) {
            alert('Please enter at least a one paragraph.');
            return
        } else {
            const reqBody = {
                "articleID": editID,
                "articleAuthor": blogDetails.author,
                "articleTopic": blogDetails.topic,
                "articleDescrip": blogDetails.descrip,
                "articlePara1": blogDetails.para1,
                "articlePara2": blogDetails.para2,
                "articlePara3": blogDetails.para3,
                "articlePara4": blogDetails.para4
            };

            // eslint-disable-next-line no-restricted-globals
            if (confirm('Do you want to update this blog article?') === false) return;
            document.querySelector('.loading-gif-container').style.display = "flex";
            axios.post(`${process.env.REACT_APP_API_LINK}/blog/update-article`, reqBody)
                .then(res => {

                    const mainImage = document.querySelector('#mainImageInput').files[0];

                    const galleryImages = document.querySelector('#galleryImagesInput').files;
                    let ifMainImage = false;
                    let ifGalleryImage = false;
                    const formdata = new FormData();
                    if (mainImage !== undefined) {
                        formdata.append('file', mainImage);
                        ifMainImage = true;
                    }
                    Array.from(galleryImages).forEach(element => {
                        ifGalleryImage = true;
                        formdata.append('file', element);
                    });
                    formdata.append('articleID', editID);
                    formdata.append('ifMainImage', ifMainImage);
                    formdata.append('ifGalleryImage', ifGalleryImage);
                    formdata.append('articleAllImages', articleAllImages);

                    axios.post(process.env.REACT_APP_API_LINK + '/update-blog-image', formdata, {
                        headers: { "Content-Type": "multipart/form-data" }
                    })
                        .then(res => {

                        })
                        .catch(error => console.log(error));
                        
                    alert('Successfully updated!');
                    cancelEdit();
                    getArticles();
                    document.querySelector('.loading-gif-container').style.display = "none";
                })
                .catch(error => console.log(error))
            // alert('OK');
        }
    }

    const deleteArticle = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to delete this blog article?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/delete-blog-article', { "articleID": editID, "articleAllImages": articleAllImages })
            .then(res => {
                alert('Successfully deleted!');
                cancelEdit();
                getArticles();
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            <div className="tab-blog-container">
                <h3>{editID === 0 ? 'Create a Blog Article' : 'Edit Blog Article'}</h3>
                <div className="input-container" >
                    <div className="det-row" >
                        <label >Author </label>
                        <input id='authorNameInput' type="text" style={{ width: "80%" }} value={blogDetails.author} name='author' onChange={handleInput} />
                    </div>
                    <div className="det-row" >
                        <label>Main Image</label>
                        <input id='mainImageInput' type="file" name='file' style={{ width: "80%" }} accept="image/png, image/jpeg" />
                    </div>
                    <div className="det-row" >
                        <label>Topic</label>
                        <input id='topicInput' type="text" style={{ width: "80%" }} value={blogDetails.topic} name='topic' onChange={handleInput} />
                    </div>
                    <div className="det-row" >
                        <label>Description</label>
                        <textarea id='descripInput' cols="48" rows="3" style={{ width: "80%" }} value={blogDetails.descrip} name='descrip' onChange={handleInput} ></textarea>
                    </div>
                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Paragraphs </label>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }}>
                        <label>1</label>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <textarea cols="65" rows="8" style={{ width: "100%" }} value={blogDetails.para1} name='para1' onChange={handleInput} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }}>
                        <label>2</label>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <textarea cols="65" rows="8" style={{ width: "100%" }} value={blogDetails.para2} name='para2' onChange={handleInput} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }}>
                        <label>3</label>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <textarea cols="65" rows="8" style={{ width: "100%" }} value={blogDetails.para3} name='para3' onChange={handleInput} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }}>
                        <label>4</label>
                        <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <textarea cols="65" rows="8" style={{ width: "100%" }} value={blogDetails.para4} name='para4' onChange={handleInput} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" >
                        <label>Gallery</label>
                        <input id='galleryImagesInput' type="file" name='file' accept="image/png, image/jpeg" style={{ width: "80%" }} multiple />
                    </div>
                    <div className="det-row" style={{ justifyContent: "center", alignItems: "center" }} >
                        {
                            (editID === 0) ? <button onClick={() => createArticle()}>Create Blog</button> : <button onClick={() => updateArticle()}>Update Blog</button>
                        }
                        {
                            (editID !== 0) && <button onClick={() => deleteArticle()} style={{ marginLeft: "10px" }}>Delete Blog</button>
                        }
                        {
                            (editID !== 0) && <button onClick={() => cancelEdit()} style={{ marginLeft: "10px" }}>Cancel</button>
                        }
                    </div>
                </div>
            </div>
            <div className="tab-blog-container">
                <h3>Article List</h3>
                <div className="blog-table">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Author</th>
                                <th>Topic</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                articles && articles.map((value, index) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>{value.date}</td>
                                            <td>{value.author}</td>
                                            <td>{value.topic}</td>
                                            <td>
                                                <a href={"/blog/article?articleID=" + value.articleID} rel="noreferrer" target="_blank">View</a>
                                                {(editID === 0) && <button onClick={() => handleEdit(value.articleID, value.author, value.topic, value.description, value.paragraph1, value.paragraph2, value.paragraph3, value.paragraph4, JSON.stringify([value.articleImage, ...JSON.parse(value.imageGallery)]))}>Edit</button>}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}