import axios from "axios";
import "./ServicesHowWorkStyles.css"
import { useEffect, useState } from "react";

export default function ServicesHowWork() {
    const CheckedIcon = "../images/checked_icon.svg";

    const [content , setContent] = useState();
    const getHowWorks = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-how-works')
        .then(res => {
            const obj = {
                "select1": {
                    "service_image": process.env.REACT_APP_API_LINK + res.data[0].service_image,
                    "key_points": res.data[0].key_points,
                    "name": res.data[0].name,
                    "topic": res.data[0].topic,
                    "description": res.data[0].description,
                },
                "select2": {
                    "service_image": process.env.REACT_APP_API_LINK + res.data[1].service_image,
                    "key_points": res.data[1].key_points,
                    "name": res.data[1].name,
                    "topic": res.data[1].topic,
                    "description": res.data[1].description,
                },
                "select3": {
                    "service_image": process.env.REACT_APP_API_LINK + res.data[2].service_image,
                    "key_points": res.data[2].key_points,
                    "name": res.data[2].name,
                    "topic": res.data[2].topic,
                    "description": res.data[2].description,
                },
                "featureImage": process.env.REACT_APP_API_LINK + res.data[3].service_image
            };
            setContent(obj);
            // console.log(res.data[3].service_image);
        })
        .catch(error => console.log(error));
    }
    useEffect(()=>{
        getHowWorks();
    },[])

    const [howSelector, setHowSelector] = useState('select1');
    const [selectedContentImage, setSelectedContentImage] = useState(content && content.select1.service_image);
    const [selectedContentKeyPoints, setSelectedContentKeyPoints] = useState(content && JSON.parse(content.select1.key_points));
    const [selectedContentTopic, setSelectedContentTopic] = useState(content && content.select1.topic);
    const [selectedContentDescription, setSelectedContentDescription] = useState(content && content.select1.description);

    useEffect(howSelectorListner, [content,howSelector]);


    function howSelectorListner() {
        setSelectedContentImage(content && content[howSelector].service_image);
        setSelectedContentKeyPoints(content && JSON.parse(content[howSelector].key_points))
        setSelectedContentTopic(content && content[howSelector].topic);
        setSelectedContentDescription(content && content[howSelector].description);
    }

    return (
        <>
            <div className="how-container">
                <div className="how-inner">
                    <div className="sub-topic">
                        <img src='../images/home_service_logo_icon.svg' alt="" />
                        <p>Our Services</p>
                    </div>
                    <h1 className="main-topic">How We Works</h1>
                    <p className="sub-description">We work closely with our customers and treat them as an asset.</p>
                    <div className="cat-wise-container">
                        <div className="selector-container">
                            <button className={howSelector === 'select1' ? "selector active" : "selector"} onClick={(e) => { setHowSelector('select1') }}>
                                <h4>{content && content.select1.name}</h4>
                                <i className="fas fa-chevron-right"></i>
                            </button>
                            <button className={howSelector === 'select2' ? "selector active" : "selector"} onClick={(e) => { setHowSelector('select2') }}>
                                <h4>{content && content.select2.name}</h4>
                                <i className="fas fa-chevron-right"></i>
                            </button>
                            <button className={howSelector === 'select3' ? "selector active" : "selector"} onClick={(e) => { setHowSelector('select3') }}>
                                <h4>{content && content.select3.name}</h4>
                                <i className="fas fa-chevron-right"></i>
                            </button>
                            <img src={content && content.featureImage} alt="" />
                        </div>
                        <div className="content-container">
                            <h2>{selectedContentTopic && selectedContentTopic}</h2>
                            <p className="sub-descrip">{selectedContentDescription && selectedContentDescription}</p>
                            <img className="image" src={selectedContentImage && selectedContentImage} alt="" />
                            <div className="k-points">
                                {
                                    selectedContentKeyPoints && selectedContentKeyPoints.map((value, index) => {
                                        return (
                                            <div className="k-point" key={index}>
                                                <img src={CheckedIcon} alt="" />
                                                <p>{value}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
