import "./FooterStyles.css";
import { useState } from "react";

export default function Footer(props) {
    const [showMail, setShowMail] = useState('contact-details');
    const [showCall, setShowCall] = useState('contact-details');
    const [showAddress, setShowAddress] = useState('contact-details');

    const clickMail = e => {
        setShowCall('contact-details')
        setShowAddress('contact-details')
        showMail === 'contact-details' ? setShowMail('contact-details show-mail') : setShowMail('contact-details')
    }
    const clickCall = e => {
        setShowMail('contact-details')
        setShowAddress('contact-details')
        showCall === 'contact-details' ? setShowCall('contact-details show-call') : setShowCall('contact-details')
    }
    const clickAddress = e => {
        setShowCall('contact-details')
        setShowMail('contact-details')
        showAddress === 'contact-details' ? setShowAddress('contact-details show-address') : setShowAddress('contact-details')
    }

    return (
        <>
            <div className="footer-container">
                <div className="top">
                    <div className="content">
                        <div className="company-logo">
                            <img src='../images/de_logo.png' alt="DE Lanka logo" />
                            <span>DE Lanka</span>
                        </div>
                        <div className="company-contacts">
                            <div className="contact-type mail">
                                <img src='../images/mail_logo.png' alt="" onClick={clickMail} />
                                <div className={showMail} >
                                    {
                                        props.branchDetails && props.branchDetails.map((data, index) => {
                                            return (
                                                <span key={index}>{data.name}  - {data.email}</span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="contact-type call">
                                <img src='../images/call_logo.png' alt="" onClick={clickCall} />
                                <div className={showCall} >
                                    {
                                        props.branchDetails && props.branchDetails.map((data, index) => {
                                            return (
                                                <span key={index}>{data.name}  - 0{data.phone1} / 0{data.phone2}</span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="contact-type address">
                                <img src='../images/location_logo.png' alt="" onClick={clickAddress} />
                                <div className={showAddress}>
                                    {
                                        props.branchDetails && props.branchDetails.map((data, index) => {
                                            return (
                                                <span key={index}>{data.name}  - {data.address}</span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="middle">
                    <div className="mini-descrip">
                        <p>DE Lanka - Your Trusted Source for Quality Motor and Electrical Solutions. We are committed to delivering exceptional products, personalized service, and reliable support. Explore our wide range of offerings and experience the DE Lanka difference.</p>
                        <div className="social-logo">
                            <a href="https://www.instagram.com/delankapvtltd/" rel="noreferrer" target="_blank"><img src='../images/footer_social_1.png' alt="" /></a>
                            <a href="https://www.facebook.com/delankamotor?mibextid=ZbWKwL" rel="noreferrer" target="_blank"><img src='../images/footer_social_2.png' alt="" /></a>
                            <a href="https://twitter.com/de_lanka49916" rel="noreferrer" target="_blank"><img src='../images/footer_social_3.png' alt="" /></a>
                            <a href="https://www.linkedin.com/jobs/" rel="noreferrer" target="_blank"><img src='../images/footer_social_4.png' alt="" /></a>
                            <a href="https://www.youtube.com/channel/UCGmRnUEkK-1eCYOMDeWTWXQ" rel="noreferrer" target="_blank"><img src='../images/footer_social_5.png' alt="" /></a>
                        </div>
                    </div>
                    <div className="footer-links">
                        <div className="links-container">
                            <h4>Explore</h4>
                            <a href="/">Home</a>
                            <a href="/about">About Us</a>
                            <a href="/services">Service</a>
                            <a href="/blog">Blog</a>
                            <a href="/contact">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <p>© DE Lanka (Pvt) Ltd. All Rights Reserved. Designed by <a href="https://www.linkedin.com/in/nuwan-lakshan/" rel="noreferrer" target="_blank"> Nuwan Lakshan</a></p>
                </div>
            </div>
        </>
    )
}