import "./ContactMapStyles.css"

export default function ContactMap() {
    return (
        <>
            <div className="map-container">
                <div className="map-inner">
                    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1ey9fmrugRVQbgwq3H28rbavAHpaGx0I&ehbc=2E312F" width="100%" height="500" title="DE Lanka Branches on Map"></iframe>
                </div>
            </div>
        </>
    )
}