import ServicesCommon from "../components/ServicesCommon";
import ServicesHowWork from "../components/ServicesHowWork";

export default function Services(){
    return (
        <>
            <ServicesCommon />
            <ServicesHowWork />
        </>
    )
}