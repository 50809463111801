import "./HeroHomeStyles.css"
import React, { useEffect, useState } from "react";
import HeroTopSection from "./HeroTopSection";
import axios from "axios";


function HeroHome(props) {
    const [heroContent, setHeroContent] = useState();
    const getHeroHome = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-hero-home')
        .then(res => {
            setHeroContent(res.data);
        })
        .catch(error => console.log(error))
    }
    useEffect(()=>{
        getHeroHome();
    },[])

    return (
        <>
            <div className="hero-section">
                <HeroTopSection branchDetails={props.branchDetails} />
                <div className="bottom">
                    <div className="hero-home-image">
                        <img src={heroContent && process.env.REACT_APP_API_LINK + heroContent.image} alt="" />
                    </div>
                    <div className="content-conatiner">
                        <div className="content">
                            <div className="sub-line">
                                <img src='../images/hero-subline-logo.svg' alt="" />
                                <p>{heroContent && heroContent.mini_title}</p>
                            </div>
                            <div className="hero-title">
                                <h2>{heroContent && heroContent.hero_title}</h2>
                            </div>
                            {heroContent && <a href="/" className="shop-now-button">Shop Now</a>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HeroHome;