import { useEffect, useState } from "react";
import "./ServicesCommonStyles.css";
import axios from "axios";

export default function ServicesCommon() {
    const [serviceContent , setServiceContent] = useState();
    const getServicesCommon = ()=>{
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-services-common')
        .then(res=>{
            setServiceContent(res.data);
        })
        .catch(error=> console.log(error));
    }
    useEffect(()=>{
        getServicesCommon();
    },[])

    return (
        <>
            <div className="service-container">
                <div className="service-inner">
                    <div className="sub-topic">
                        <img src='../images/home_service_logo_icon.svg' alt="" />
                        <p>Our Services</p>
                    </div>
                    <h1 className="main-topic">Our Services</h1>
                    <p className="sub-description">Explore a wide range of motor and electrical products tailored to your needs.</p>
                    <div className="card-container">
                        {
                            serviceContent && serviceContent.map((details, index) => {
                                return (
                                    <div className="card" key={index}>
                                        <img src={process.env.REACT_APP_API_LINK + details.image} alt="" />
                                        <h3>{details.topic}</h3>
                                        <p>{details.description}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}