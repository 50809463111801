import { useEffect, useState } from 'react';
import './TabServicesStyles.css';
import axios from 'axios';

export default function TabServices() {
    const [content, setContent] = useState();
    const getHowWorks = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-how-works')
            .then(res => {
                const select1Keypoints = JSON.parse(res.data[0].key_points);
                for (let i = JSON.parse(res.data[0].key_points).length; i < 5; i++) {
                    select1Keypoints.push("");
                }
                const select2Keypoints = JSON.parse(res.data[1].key_points);
                for (let i = JSON.parse(res.data[1].key_points).length; i < 5; i++) {
                    select2Keypoints.push("");
                }
                const select3Keypoints = JSON.parse(res.data[2].key_points);
                for (let i = JSON.parse(res.data[2].key_points).length; i < 5; i++) {
                    select3Keypoints.push("");
                }
                const obj = {
                    "select1": {
                        "service_image": process.env.REACT_APP_API_LINK + res.data[0].service_image,
                        "key_points": select1Keypoints,
                        "name": res.data[0].name,
                        "topic": res.data[0].topic,
                        "description": res.data[0].description,
                    },
                    "select2": {
                        "service_image": process.env.REACT_APP_API_LINK + res.data[1].service_image,
                        "key_points": select2Keypoints,
                        "name": res.data[1].name,
                        "topic": res.data[1].topic,
                        "description": res.data[1].description,
                    },
                    "select3": {
                        "service_image": process.env.REACT_APP_API_LINK + res.data[2].service_image,
                        "key_points": select3Keypoints,
                        "name": res.data[2].name,
                        "topic": res.data[2].topic,
                        "description": res.data[2].description,
                    },
                    "featureImage": process.env.REACT_APP_API_LINK + res.data[3].service_image
                };
                setContent(obj);
                // console.log(res.data);
            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getHowWorks();
    }, [])

    const updateSelectorImage = (serviceID, currentImage) => {
        const selectorImage = document.querySelector('#selectorImage_' + serviceID).files[0];
        if (selectorImage === undefined) {
            alert('Please select an image to update!');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero image?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        const formdata = new FormData();
        formdata.append('file', selectorImage);
        formdata.append('deleteFile', '.' + currentImage);

        axios.post(process.env.REACT_APP_API_LINK + '/upload-image', formdata, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                axios.post(process.env.REACT_APP_API_LINK + '/content/update-how-works', { "serviceType": serviceID, "contentType": "image", "contentData": '/images/' + selectorImage.name })
                    .then(res => {
                        getHowWorks();
                        alert('Successfully updated!');
                        document.querySelector('.loading-gif-container').style.display = "none";
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error));
    }

    const updateSelectorName = (serviceID) => {
        const selectorName = document.querySelector('#selectorName_' + serviceID).value;
        if (selectorName.length === 0) {
            alert('Please enter valid data to update name.');
            return;
        }
        else if (selectorName.length > 30) {
            alert('Name should less than 30 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update name?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-how-works', { "serviceType": serviceID, "contentType": "name", "contentData": selectorName })
            .then(res => {
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateSelectorTopic = (serviceID) => {
        const selectorTopic = document.querySelector('#selectorTopic_' + serviceID).value;
        if (selectorTopic.length === 0) {
            alert('Please enter valid data to update topic.');
            return;
        }
        else if (selectorTopic.length > 50) {
            alert('Topic should less than 50 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update topic?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-how-works', { "serviceType": serviceID, "contentType": "topic", "contentData": selectorTopic })
            .then(res => {
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateSelectorDescription = (serviceID) => {
        const selectorDescription = document.querySelector('#selectorDescription_' + serviceID).value;
        if (selectorDescription.length === 0) {
            alert('Please enter valid data to update description.');
            return;
        }
        else if (selectorDescription.length > 100) {
            alert('Description should less than 100 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update description?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-how-works', { "serviceType": serviceID, "contentType": "description", "contentData": selectorDescription })
            .then(res => {
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateSelectorKeypoints = (serviceID) => {
        const keypointsArray = [];
        let ifOK = true;
        for (let i = 1; i <= 5; i++) {
            const desc = document.querySelector('#selectorKeypoint_'+serviceID+'_'+ i);

            desc.style.color = "black";

            if (desc.value.length > 250) {
                alert('Descriptions should less than 250 characters');
                desc.style.color = "red";
                ifOK = false;
            } else if ((desc.value.length > 0)) {
                keypointsArray.push( desc.value );
            }
        }
        if (ifOK) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Do you want to update keypoints?') === false) return;
            document.querySelector('.loading-gif-container').style.display = "flex";
            axios.post(process.env.REACT_APP_API_LINK + '/content/update-how-works', { "serviceType": serviceID, "contentType": "keypoints", "contentData": JSON.stringify(keypointsArray) })
                .then(res => {
                    alert('Successfully updated!');
                    document.querySelector('.loading-gif-container').style.display = "none";
                })
                .catch(error => console.log(error))
        }
    }    

    return (
        <>
            <div className="tab-service-container">
                <h3 className='home-topics'>How We Work</h3>
                <div className="input-container" >
                    <div className="det-row" >
                        <label style={{ fontWeight: "bold" }}>Selector 1 </label>
                        <input id={'selectorName_' + 1} type="text" defaultValue={content && content.select1.name} />
                        <button onClick={() => updateSelectorName(1)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Image</label>
                        <a href={content && content.select1.service_image} rel="noreferrer" target="_blank">Preview</a>
                        <input id={'selectorImage_1'} type="file" name='file' accept="image/png, image/jpeg" />
                        <button onClick={() => updateSelectorImage(1, content.select1.service_image)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Topic</label>
                        <input id={'selectorTopic_' + 1} type="text" defaultValue={content && content.select1.topic} />
                        <button onClick={() => updateSelectorTopic(1)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Description</label>
                        <textarea name="" id={'selectorDescription_' + 1} cols="48" rows="2" defaultValue={content && content.select1.description}></textarea>
                        <button onClick={() => updateSelectorDescription(1)}>Update</button>
                    </div>
                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Keypoints </label>
                        <button onClick={() => updateSelectorKeypoints(1)}>Update Keypoints</button>
                    </div>
                    {
                        content && content.select1.key_points.map((value, index) => {
                            return (
                                <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} key={index + 1}>
                                    <label>{index + 1}</label>
                                    <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <textarea name="" id={'selectorKeypoint_' + 1 + '_' + (index + 1)} cols="65" rows="2" style={{ width: "100%" }} defaultValue={value}></textarea>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="input-container" >
                    <div className="det-row" >
                        <label style={{ fontWeight: "bold" }}>Selector 2 </label>
                        <input id={'selectorName_' + 2} type="text" defaultValue={content && content.select2.name} />
                        <button onClick={() => updateSelectorName(2)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Image</label>
                        <a href={content && content.select2.service_image} rel="noreferrer" target="_blank">Preview</a>
                        <input id={'selectorImage_2'} type="file" name='file' accept="image/png, image/jpeg" />
                        <button onClick={() => updateSelectorImage(2, content.select2.service_image)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Topic</label>
                        <input id={'selectorTopic_' + 2} type="text" defaultValue={content && content.select2.topic} />
                        <button onClick={() => updateSelectorTopic(2)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Description</label>
                        <textarea name="" id={'selectorDescription_' + 2} cols="48" rows="2" defaultValue={content && content.select2.description}></textarea>
                        <button onClick={() => updateSelectorDescription(2)}>Update</button>
                    </div>
                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Keypoints </label>
                        <button onClick={() => updateSelectorKeypoints(2)}>Update Keypoints</button>
                    </div>
                    {
                        content && content.select2.key_points.map((value, index) => {
                            return (
                                <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} key={index + 1}>
                                    <label>{index + 1}</label>
                                    <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <textarea name="" id={'selectorKeypoint_' + 2 + '_' + (index + 1)} cols="65" rows="2" style={{ width: "100%" }} defaultValue={value}></textarea>

                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="input-container" >
                    <div className="det-row" >
                        <label style={{ fontWeight: "bold" }}>Selector 3 </label>
                        <input id={'selectorName_' + 3} type="text" defaultValue={content && content.select3.name} />
                        <button onClick={() => updateSelectorName(3)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Image</label>
                        <a href={content && content.select3.service_image} rel="noreferrer" target="_blank">Preview</a>
                        <input id={'selectorImage_3'} type="file" name='file' accept="image/png, image/jpeg" />
                        <button onClick={() => updateSelectorImage(3, content.select3.service_image)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Topic</label>
                        <input id={'selectorTopic_' + 3} type="text" defaultValue={content && content.select3.topic} />
                        <button onClick={() => updateSelectorTopic(3)}>Update</button>
                    </div>
                    <div className="det-row" >
                        <label>Description</label>
                        <textarea name="" id={'selectorDescription_' + 3} cols="48" rows="2" defaultValue={content && content.select3.description}></textarea>
                        <button onClick={() => updateSelectorDescription(3)}>Update</button>
                    </div>
                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Keypoints </label>
                        <button onClick={() => updateSelectorKeypoints(3)}>Update Keypoints</button>
                    </div>
                    {
                        content && content.select3.key_points.map((value, index) => {
                            return (
                                <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} key={index + 1}>
                                    <label>{index + 1}</label>
                                    <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <textarea name="" id={'selectorKeypoint_' + 3 + '_' + (index + 1)} cols="65" rows="2" style={{ width: "100%" }} defaultValue={value}></textarea>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}