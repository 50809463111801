import axios from "axios";
import { useEffect, useState } from "react";


export default function TabAboutVision() {

    const [visionDescription, setVisionDescription] = useState();
    const [missionDescription, setMissionDescription] = useState();
    const [visionKeyPoints, setVisionKeyPoints] = useState();
    const [missionKeyPoints, setMissionKeyPoints] = useState();
    const getVision = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-vision-mission')
            .then(res => {

                setVisionDescription(res.data[0].description);
                setMissionDescription(res.data[1].description);

                const visionKeyPoint = JSON.parse(res.data[0].keypoints);
                const missionKeyPoint = JSON.parse(res.data[1].keypoints);
                for (let i = JSON.parse(res.data[0].keypoints).length; i < 10; i++) {
                    visionKeyPoint.push({ "topic": "", "desc": "" });
                }
                setVisionKeyPoints(visionKeyPoint)
                for (let i = JSON.parse(res.data[1].keypoints).length; i < 10; i++) {
                    missionKeyPoint.push({ "topic": "", "desc": "" });
                }
                setMissionKeyPoints(missionKeyPoint)


            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getVision();
    }, [])

    const updateVisionMissionDescrip = (elementID, mainType) => {
        const selectedDescrip = document.querySelector('#' + elementID).value;
        if (selectedDescrip.length === 0) {
            alert('Please enter a valid topic to update.');
            return;
        } else if (selectedDescrip.length > 800) {
            alert('Topic should be lover than 800 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update description?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-vision-mission', { "mainType": mainType, "contentType": "description", "contentData": selectedDescrip })
            .then(res => {
                getVision();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateVisionMissionKeypoints = (mainType) => {
        const keypointsArray = [];
        // let maxTopic = 37 , maxDesc =186;
        let ifOK = true;
        for (let i = 1; i <= 10; i++) {
            const topic = document.querySelector('#' + mainType + 'KeyPointTopic_' + i);
            const desc = document.querySelector('#' + mainType + 'KeyPointDesc_' + i);

            topic.style.color = "black";
            desc.style.color = "black";

            if (topic.value.length > 40) {
                alert('Topics should less than 40 characters');
                topic.style.color = "red";
                ifOK = false;
            } else if (desc.value.length > 200) {
                alert('Descriptions should less than 200 characters');
                desc.style.color = "red";
                ifOK = false;
            } else if ((topic.value.length > 0) && (desc.value.length > 0)) {
                keypointsArray.push({
                    "topic": topic.value,
                    "desc": desc.value
                });
            }
        }
        if (ifOK) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Do you want to update description?') === false) return;
            document.querySelector('.loading-gif-container').style.display = "flex";
            axios.post(process.env.REACT_APP_API_LINK + '/content/update-vision-mission', { "mainType": mainType, "contentType": "keypoints", "contentData": JSON.stringify(keypointsArray) })
                .then(res => {
                    alert('Successfully updated!');
                    document.querySelector('.loading-gif-container').style.display = "none";
                })
                .catch(error => console.log(error))
        }
    }

    return (
        <>
            <div className="tab-about--container">
                <h3 className='home-topics'>Vision</h3>
                <div className="input-container">
                    <div className="det-row">
                        <label>Description</label>
                        <textarea name="" id="visionDescription" cols="48" rows="6" defaultValue={visionDescription && visionDescription}></textarea>
                        <button onClick={() => updateVisionMissionDescrip('visionDescription', 'vision')}>Update</button>
                    </div>
                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Keypoints </label>
                        <button onClick={() => updateVisionMissionKeypoints('vision')}>Update Keypoints</button>
                    </div>
                    {
                        visionKeyPoints && visionKeyPoints.map((value, index) => {
                            return (
                                <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} key={index} >
                                    <label>{index + 1}</label>
                                    <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                            <input id={'visionKeyPointTopic_' + (index + 1)} type="text" style={{ width: "100%" }} defaultValue={value.topic} />
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                            <textarea name="" id={'visionKeyPointDesc_' + (index + 1)} cols="65" rows="2" style={{ width: "100%" }} defaultValue={value.desc}></textarea>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="tab-about--container">
                <h3 className='home-topics'>Mission</h3>
                <div className="input-container">
                    <div className="det-row">
                        <label>Description</label>
                        <textarea name="" id="missionDescription" cols="48" rows="6" defaultValue={missionDescription && missionDescription}></textarea>
                        <button onClick={() => updateVisionMissionDescrip('missionDescription', 'mission')}>Update</button>
                    </div>
                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Keypoints </label>
                        <button onClick={() => updateVisionMissionKeypoints('mission')}>Update Keypoints</button>
                    </div>
                    {
                        missionKeyPoints && missionKeyPoints.map((value, index) => {
                            return (
                                <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} key={index} >
                                    <label>{index + 1}</label>
                                    <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                            <input id={'missionKeyPointTopic_' + (index + 1)} type="text" style={{ width: "100%" }} defaultValue={value.topic} />
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                            <textarea name="" id={'missionKeyPointDesc_' + (index + 1)} cols="65" rows="2" style={{ width: "100%" }} defaultValue={value.desc}></textarea>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}