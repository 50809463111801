import AboutSimple from "../components/AboutSimple";
import AboutVision from "../components/AboutVision";
import AboutTeam from "../components/AboutTeam";

export default function About() {
    return (
        <>
            
            <AboutSimple aboutSimpleImg = '../images/about_about_image.png' ifReverse={true} />
            <AboutVision />
            <AboutTeam />
        </>
    )
}