import AboutSimple from "../components/AboutSimple";
import ServicesCommon from "../components/ServicesCommon";
import WhyChoose from "../components/WhyChoose";
import React from "react";

function Home(){
    return(
        <>
            
            <AboutSimple aboutSimpleImg = '../images/home_about_image.jpg' ifReverse={false} />
            <ServicesCommon />
            <WhyChoose />
        </>
    )
}

export default Home;