import { useEffect, useState } from 'react';
import './TabContactStyles.css'
import axios from 'axios';

export default function TabContact() {
    const [branchDetails, setBranchDetails] = useState();
    const getBranchDetails = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/branch-details')
            .then(res => {
                setBranchDetails(res.data);
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        getBranchDetails();
    }, [])

    const updateBranch = (index, brID) => {
        const branch = {
            "brID": brID,
            "name": document.querySelector('#name_' + index).value,
            "address": document.querySelector('#address_' + index).value,
            "phone1": document.querySelector('#phone1_' + index).value,
            "phone2": document.querySelector('#phone2_' + index).value,
            "email": document.querySelector('#email_' + index).value,
        }
        if (branch.name.length === 0) {
            alert("Please enter branch name.");
            return;
        }
        else if (branch.address.length === 0) {
            alert("Please enter branch address.");
            return;
        }
        else if (branch.phone1.length !== 9) {
            alert("Please enter branch phone number without zero (77******).");
            return;
        }
        else if (branch.phone2.length !== 9) {
            alert("Please enter branch phone number without zero (77******).");
            return;
        }
        else if (branch.email.length === 0) {
            alert("Please enter branch email address");
            return;
        } else {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Do you want to update this branch details?') === false) return;
            document.querySelector('.loading-gif-container').style.display = "flex";
            axios.post(process.env.REACT_APP_API_LINK + '/content/update-branch', branch)
                .then(res => {
                    alert('Details successfully updated!');
                    getBranchDetails();
                    document.querySelector('.loading-gif-container').style.display = "none";
                })
                .catch(error => console.log(error))
        }
    }

    return (
        <>
            <div className="contact-table">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Branch Name</th>
                            <th>Address</th>
                            <th>Phone 01</th>
                            <th>Phone 02</th>
                            <th>Email</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            branchDetails && branchDetails.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td><input type="text" defaultValue={data.name} id={'name_' + (index + 1)} /></td>
                                        <td><input type="text" defaultValue={data.address} id={'address_' + (index + 1)} /></td>
                                        <td><input type="text" defaultValue={data.phone1} id={'phone1_' + (index + 1)} /></td>
                                        <td><input type="text" defaultValue={data.phone2} id={'phone2_' + (index + 1)} /></td>
                                        <td><input type="text" defaultValue={data.email} id={'email_' + (index + 1)} /></td>
                                        <td><i className="fas fa-upload" onClick={() => updateBranch(index + 1, data.brID)} ></i></td>
                                    </tr>

                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}