import "./RegisterFormStyles.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function RegisterForm(){
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        username : '',
        newPassword : '',
        confirmPassword : ''
    });
    
    useEffect(()=>{
        axios.get(process.env.REACT_APP_API_LINK + '/validate')
        .then(res => {
            if(res.data.valid){
                navigate('/settings')
            }else{
                navigate('/register');
            }
        })
        .catch(err => console.log(err));
    },[navigate]);

    const handleInput = (event) => {
        setInputValues(prev => ({...prev, [event.target.name]:[event.target.value]}));
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if(inputValues.username.length === 0){
            alert('Please enter valid username');
            return 
        }
        else if((inputValues.newPassword.length === 0) || (inputValues.confirmPassword.length === 0)){
            alert('Please enter valid passworrd');
            return 
        }
        else if((inputValues.newPassword.toString() !== inputValues.confirmPassword.toString())){
            alert('Passwrord dose not matched!');
            return 
        }
        else{
            // eslint-disable-next-line no-restricted-globals
            if(confirm('Do you want to complete register?')===false)return;
            const values = {
                "username":inputValues.username,
                "password":inputValues.newPassword,
            }
            axios.post(process.env.REACT_APP_API_LINK + '/register', values)
            .then(res => navigate('/login'))
            .catch(err => console.log(err));
        }
    }


    const navigateTo = (event) => {
        event.preventDefault();    
        navigate('/login');
    }
    return (
        <>
            <div className="reg-form-container">
                <div className="reg-form-inner">
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <label htmlFor="username" ><strong>Username</strong></label>
                            <input type="text" name="username" placeholder="Enter Username" onChange={handleInput} />
                        </div>
                        <div className="field">
                            <label htmlFor="password" ><strong>New Password</strong></label>
                            <input type="password" name="newPassword" placeholder="Enter Password" onChange={handleInput}/>
                        </div>
                        <div className="field">
                            <label htmlFor="password" ><strong>Confirm Password</strong></label>
                            <input type="password" name="confirmPassword" placeholder="Re-enter Password" onChange={handleInput}/>
                        </div>
                        <button type="submit" >Register</button>
                        <a href="/" onClick={navigateTo}>Login</a>
                    </form>
                </div>
            </div>
        </>
    )
}