import { useState } from 'react';
import './SettingsTabpaneStyles.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import TabUsers from './Settings/TabUsers';
import TabContact from './Settings/TabContact';
import TabHome from './Settings/TabHome';
import TabAbout from './Settings/TabAbout';
import TabServices from './Settings/TabServices';
import TabBlog from './Settings/TabBlog';

export default function SettingsTabpane(props) {
    const navigate = useNavigate();

    const [toggleTab, setToggleTab] = useState(1);
    const handleToggleTab = (index) => {
        setToggleTab(index);
    }

    const logout = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to logout?') === false) return;
        axios.post(process.env.REACT_APP_API_LINK + '/logout')
            .then(res => {
                alert(res.data);
                if (res.data === 'Logout successful') navigate('/login');
            })
            .catch(err => console.log(err));
    }

    const ifAdmin = props.userDetails && props.userDetails.userdata.usertype === 'admin';

    return (
        <>
            <div className='settings-tab-container'>
                <div className='settings-tab-inner'>
                    <div className="current-user">
                        <span><i className="fas fa-user-shield"></i> {props.userDetails && props.userDetails.username}</span> <span onClick={() => logout()}>Logout <i className="fas fa-sign-out-alt"></i></span>
                    </div>
                    <div className="tabpane-container">
                        <div className="tab-header">
                            <div className={toggleTab === 1 ? "tab-selector active" : "tab-selector"} onClick={() => handleToggleTab(1)} >Home</div>
                            <div className={toggleTab === 2 ? "tab-selector active" : "tab-selector"} onClick={() => handleToggleTab(2)} >About</div>
                            <div className={toggleTab === 3 ? "tab-selector active" : "tab-selector"} onClick={() => handleToggleTab(3)} >Services</div>
                            <div className={toggleTab === 4 ? "tab-selector active" : "tab-selector"} onClick={() => handleToggleTab(4)} >Blog</div>
                            <div className={toggleTab === 5 ? "tab-selector active" : "tab-selector"} onClick={() => handleToggleTab(5)} >Contact</div>
                            {ifAdmin && <div className={toggleTab === 6 ? "tab-selector active" : "tab-selector"} onClick={() => handleToggleTab(6)} >Users</div>}
                        </div>
                        <div className="content-holder">
                            <div className={toggleTab === 1 ? "tab-content active" : "tab-content"}><TabHome /></div>
                            <div className={toggleTab === 2 ? "tab-content active" : "tab-content"}><TabAbout /></div>
                            <div className={toggleTab === 3 ? "tab-content active" : "tab-content"}><TabServices /></div>
                            <div className={toggleTab === 4 ? "tab-content active" : "tab-content"}><TabBlog /></div>
                            <div className={toggleTab === 5 ? "tab-content active" : "tab-content"}><TabContact /></div>
                            {ifAdmin && <div className={toggleTab === 6 ? "tab-content active" : "tab-content"}><TabUsers /></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}