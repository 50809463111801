import axios from "axios";
import { useEffect, useState } from "react"


export default function TabHomeWhyChoose() {
    const [chooseContent, setChooseContent] = useState();
    const getWhyChoose = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-why-choose')
            .then(res => {
                // console.log(res.data[0]);
                setChooseContent(res.data[0]);
            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getWhyChoose();
    }, [])

    const updateWhyChooseImage = (currentImage) => {
        const serviceImage = document.querySelector('#chooseImage').files[0];
        if (serviceImage === undefined) {
            alert('Please select an image to update!');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero image?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        const formdata = new FormData();
        formdata.append('file', serviceImage);
        formdata.append('deleteFile', '.' + currentImage);

        axios.post(process.env.REACT_APP_API_LINK + '/upload-image', formdata, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                axios.post(process.env.REACT_APP_API_LINK + '/content/update-why-choose', { "contentType": "image", "contentData": '/images/' + serviceImage.name })
                    .then(res => {
                        getWhyChoose();
                        alert('Successfully updated!');
                        document.querySelector('.loading-gif-container').style.display = "none";
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error));
    }

    const updateWhyChooseCounter = () => {
        const counterArray = [
            {
                "sec1": document.querySelector('#chooseCountSec1_1').value,
                "sec2": document.querySelector('#chooseCountSec2_1').value
            },
            {
                "sec1": document.querySelector('#chooseCountSec1_2').value,
                "sec2": document.querySelector('#chooseCountSec2_2').value
            },
            {
                "sec1": document.querySelector('#chooseCountSec1_3').value,
                "sec2": document.querySelector('#chooseCountSec2_3').value
            }
        ];
        let ifOK = true;
        counterArray.forEach((value, index) => {
            document.querySelector('#chooseCountSec1_' + (index + 1)).style.color = "black";
            document.querySelector('#chooseCountSec2_' + (index + 1)).style.color = "black";
            if ((value.sec1.length === 0) || (value.sec2.length === 0)) {
                alert('Please enter all counter fileds');
                ifOK = false;
                return;
            } else if (value.sec1.length > 6) {
                document.querySelector('#chooseCountSec1_' + (index + 1)).style.color = "red";
                alert('Numbers field should less than 6 characters');
                ifOK = false;
                return;
            } else if (value.sec2.length > 15) {
                document.querySelector('#chooseCountSec2_' + (index + 1)).style.color = "red";
                alert('Description field should less than 15 characters');
                ifOK = false;
                return;
            }
        })

        if (ifOK) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Do you want to update description?') === false) return;
            document.querySelector('.loading-gif-container').style.display = "flex";
            axios.post(process.env.REACT_APP_API_LINK + '/content/update-why-choose', { "contentType": "imgInnerDet", "contentData": JSON.stringify(counterArray) })
                .then(res => {
                    alert('Successfully updated!');
                    document.querySelector('.loading-gif-container').style.display = "none";
                })
                .catch(error => console.log(error))
        }

    }

    const updateWhyChooseTopic = (serviceID) => {
        const chooseTopic = document.querySelector('#chooseTopic').value;
        if (chooseTopic.length === 0) {
            alert('Please enter a valid topic to update.');
            return;
        } else if (chooseTopic.length > 45) {
            alert('Topic should be lover than 45 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update description?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-why-choose', { "contentType": "topic", "contentData": chooseTopic })
            .then(res => {
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateWhyChooseDescrip = () => {
        const chooseDescrip = document.querySelector('#chooseDescrip').value;
        if (chooseDescrip.length === 0) {
            alert('Please enter a valid topic to update.');
            return;
        } else if (chooseDescrip.length > 300) {
            alert('Topic should be lover than 300 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update description?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-why-choose', { "contentType": "description", "contentData": chooseDescrip })
            .then(res => {
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateWhyChooseKeypoints = () => {
        const keypointsArray = [
            {
                "topic": document.querySelector('#chooseKeyPointTopic_1').value,
                "desc": document.querySelector('#chooseKeyPointDesc_1').value
            },
            {
                "topic": document.querySelector('#chooseKeyPointTopic_2').value,
                "desc": document.querySelector('#chooseKeyPointDesc_2').value
            },
            {
                "topic": document.querySelector('#chooseKeyPointTopic_3').value,
                "desc": document.querySelector('#chooseKeyPointDesc_3').value
            },
            {
                "topic": document.querySelector('#chooseKeyPointTopic_4').value,
                "desc": document.querySelector('#chooseKeyPointDesc_4').value
            },
            {
                "topic": document.querySelector('#chooseKeyPointTopic_5').value,
                "desc": document.querySelector('#chooseKeyPointDesc_5').value
            },
            {
                "topic": document.querySelector('#chooseKeyPointTopic_6').value,
                "desc": document.querySelector('#chooseKeyPointDesc_6').value
            },
            {
                "topic": document.querySelector('#chooseKeyPointTopic_7').value,
                "desc": document.querySelector('#chooseKeyPointDesc_7').value
            },
            {
                "topic": document.querySelector('#chooseKeyPointTopic_8').value,
                "desc": document.querySelector('#chooseKeyPointDesc_8').value
            }
        ];

        let ifOK = true;
        keypointsArray.forEach((value, index) => {
            document.querySelector('#chooseKeyPointTopic_' + (index + 1)).style.color = "black";
            document.querySelector('#chooseKeyPointDesc_' + (index + 1)).style.color = "black";
            if ((value.topic.length === 0) || (value.desc.length === 0)) {
                ifOK = false;
                alert('Please enter all keypoints details!'); return;
            } else if (value.topic.length > 30) {
                ifOK = false;
                document.querySelector('#chooseKeyPointTopic_' + (index + 1)).style.color = "red";
                alert('All topics should less than 30 characters');
                return;
            } else if (value.desc.length > 140) {
                ifOK = false;
                document.querySelector('#chooseKeyPointDesc_' + (index + 1)).style.color = "red";
                alert('All descriptions should less than 140 characters');
                return;
            }
        });
        if (ifOK) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Do you want to update keypoints?') === false) return;
            document.querySelector('.loading-gif-container').style.display = "flex";
            axios.post(process.env.REACT_APP_API_LINK + '/content/update-why-choose', { "contentType": "keypoints", "contentData": JSON.stringify(keypointsArray) })
                .then(res => {
                    alert('Successfully updated!');
                    document.querySelector('.loading-gif-container').style.display = "none";
                })
                .catch(error => console.log(error))
        }
    }

    return (
        <>
            <div className="tab-home--container">
                <h3 className='home-topics'>Home Why Chooese</h3>
                <div className="inp-container">
                    <div className="det-row">
                        <label>Image</label>
                        <a href={chooseContent && process.env.REACT_APP_API_LINK + chooseContent.image} rel="noreferrer" target="_blank">Preview </a>
                        <input type="file" id="chooseImage" name='file' accept="image/png, image/jpeg" />
                        <button onClick={() => updateWhyChooseImage(chooseContent.image)}>Update</button>
                    </div>
                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Counter </label>
                        <button onClick={() => updateWhyChooseCounter()}>Update Counter</button>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px" }} >
                        <label>1</label>
                        <input id='chooseCountSec1_1' defaultValue={chooseContent && JSON.parse(chooseContent.imgInnerDet)[0].sec1} type="text" style={{ width: "20%" }} placeholder="Number" />
                        <input id='chooseCountSec2_1' defaultValue={chooseContent && JSON.parse(chooseContent.imgInnerDet)[0].sec2} type="text" style={{ width: "50%" }} placeholder="Description" />
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px" }} >
                        <label>2</label>
                        <input id='chooseCountSec1_2' defaultValue={chooseContent && JSON.parse(chooseContent.imgInnerDet)[1].sec1} type="text" style={{ width: "20%" }} placeholder="Number" />
                        <input id='chooseCountSec2_2' defaultValue={chooseContent && JSON.parse(chooseContent.imgInnerDet)[1].sec2} type="text" style={{ width: "50%" }} placeholder="Description" />
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px 5px 0px" }} >
                        <label>3</label>
                        <input id='chooseCountSec1_3' defaultValue={chooseContent && JSON.parse(chooseContent.imgInnerDet)[2].sec1} type="text" style={{ width: "20%" }} placeholder="Number" />
                        <input id='chooseCountSec2_3' defaultValue={chooseContent && JSON.parse(chooseContent.imgInnerDet)[2].sec2} type="text" style={{ width: "50%" }} placeholder="Description" />
                    </div>

                    <div className="det-row" style={{ borderWidth: "2px 0px 1px 0px" }}>
                        <label>Topic</label>
                        <input type="text" id="chooseTopic" defaultValue={chooseContent && chooseContent.topic} />
                        <button onClick={() => updateWhyChooseTopic()}>Update</button>
                    </div>
                    <div className="det-row">
                        <label>Description</label>
                        <textarea name="" id='chooseDescrip' cols="48" rows="5" defaultValue={chooseContent && chooseContent.description}></textarea>
                        <button onClick={() => updateWhyChooseDescrip()}>Update</button>
                    </div>

                    <div className="det-row" style={{ borderBottom: "none" }}>
                        <label >Keypoints </label>
                        <button onClick={() => updateWhyChooseKeypoints()}>Update Keypoints</button>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>1</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_1' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[0].topic} />
                            </div>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_1' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[0].desc}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>2</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_2' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[1].topic} />
                            </div>
                            <div style={{ wi1th: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_2' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[1].desc}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>3</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_3' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[2].topic} />
                            </div>
                            <div style={{ wi1th: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_3' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[2].desc}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>4</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_4' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[3].topic} />
                            </div>
                            <div style={{ wi1th: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_4' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[3].desc}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>5</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_5' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[4].topic} />
                            </div>
                            <div style={{ wi1th: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_5' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[4].desc}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>6</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_6' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[5].topic} />
                            </div>
                            <div style={{ wi1th: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_6' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[5].desc}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>7</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_7' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[6].topic} />
                            </div>
                            <div style={{ wi1th: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_7' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[6].desc}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="det-row" style={{ border: "none", padding: "2px 0px", alignItems: "flex-start" }} >
                        <label>8</label>
                        <div style={{ width: "84%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Topic</label>
                                <input id='chooseKeyPointTopic_8' type="text" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[7].topic} />
                            </div>
                            <div style={{ wi1th: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ width: "70px", marginRight: "5px" }}>Point</label>
                                <textarea name="" id='chooseKeyPointDesc_8' cols="65" rows="2" style={{ width: "100%" }} defaultValue={chooseContent && JSON.parse(chooseContent.keypoints)[7].desc}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}