import axios from "axios";
import { useEffect, useState } from "react";


export default function TabAboutTeam() {
    const [teamMembers, setTeamMembers] = useState();
    const getTeamMembers = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-about-team')
            .then(res => {
                setTeamMembers(res.data);
            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getTeamMembers();
    }, [])

    const updateMemberImage = (memberID, index, currentImage) => {
        const memeberImage = document.querySelector('#memberImage_' + index).files[0];
        if (memeberImage === undefined) {
            alert('Please select an image to update!');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero image?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        const formdata = new FormData();
        formdata.append('file', memeberImage);
        formdata.append('deleteFile', '.' + currentImage);

        axios.post(process.env.REACT_APP_API_LINK + '/upload-image', formdata, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                axios.post(process.env.REACT_APP_API_LINK + '/content/update-about-team', { "memberID": memberID, "contentType": "image", "contentData": '/images/' + memeberImage.name })
                    .then(res => {
                        getTeamMembers();
                        alert('Successfully updated!');
                        document.querySelector('.loading-gif-container').style.display = "none";
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error));
    }

    const updateMemberName = (memberID, index) => {
        const memberName = document.querySelector('#memberName_' + index).value;
        if (memberName.length === 0) {
            alert('Please enter valid data to update name.');
            return;
        }
        else if (memberName.length > 40) {
            alert('Name should less than 40 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update name?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-about-team', { "memberID": memberID, "contentType": "name", "contentData": memberName })
            .then(res => {
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateMemberDesignation = (memberID, index) => {
        const memberDesignation = document.querySelector('#memberDesignation_' + index).value;
        if (memberDesignation.length === 0) {
            alert('Please enter valid data to update designation.');
            return;
        }
        else if (memberDesignation.length > 40) {
            alert('Designation should less than 40 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update designation?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-about-team', { "memberID": memberID, "contentType": "designation", "contentData": memberDesignation })
            .then(res => {
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            <div className="tab-about--container">
                <h3 className='home-topics'>Team</h3>
                <div className="team-table">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                teamMembers && teamMembers.map((value, index) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="cell-div" >
                                                    <input type="file" id={"memberImage_" + (index + 1)} name='file' accept="image/png, image/jpeg" style={{ width: "90px" }} />
                                                    <div>
                                                        <a href={process.env.REACT_APP_API_LINK + value.image} rel="noreferrer" target="_blank">Preview </a>
                                                        <button onClick={() => updateMemberImage(value.id, (index + 1), value.image)}>Update</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-div" >
                                                    <input type="text" id={"memberName_" + (index + 1)} defaultValue={value.name} />
                                                    <button onClick={() => updateMemberName(value.id, (index + 1))}>Update</button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-div" >
                                                    <input type="text" id={"memberDesignation_" + (index + 1)} defaultValue={value.designation} />
                                                    <button onClick={() => updateMemberDesignation(value.id, (index + 1))}>Update</button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}