import './App.css';
import NavBar from './components/Navbar';
import Home from './routes/Home';
import About from './routes/About';
import Services from './routes/Services';
import Blog from './routes/Blog';
import Contact from './routes/Contact';
import { Route, Routes } from 'react-router-dom';
import BlogArticle from './routes/BlogArticle';
import Login from './routes/Login';
import Register from './routes/Register';
import Settings from './routes/Settings';
import Footer from "./components/Footer";
import { useLocation } from 'react-router-dom'
import HeroHome from "./components/HeroHome";
import HeroOther from "./components/HeroOther";
import { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState(null);
  const [ifUser, setIfUser] = useState(null);

  //Checking if user logged in
  axios.defaults.withCredentials = true;
  if (currentLocation !== location.pathname) {
    setCurrentLocation(location.pathname);
    axios.get(process.env.REACT_APP_API_LINK + '/validate')
      .then(res => {
        if (res.data.valid) {
          setIfUser(res.data);
        } else {
          setIfUser(null);
        }
      })
      .catch(err => console.log(err));
  }
  //Checking if user logged in

  const [brDetails, setBrDetails] = useState(null);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_LINK + '/content/branch-details')
      .then(res => {
        setBrDetails(res.data)

      })
      .catch(err => console.log(err));
  }, [])

  const pageNames = {
    "/": "Home",
    "/about": "About Us",
    "/services": "Services",
    "/blog": "Blog",
    "/blog/article": "Blog",
    "/contact": "Contact",
    "/login": "Login",
    "/register": "Register",
    "/settings": "Settings",
  }

  return (
    <div className="App">
      <NavBar userData={ifUser} />
      {
        location.pathname === '/' ?
          <HeroHome branchDetails={brDetails} />
          :
          <HeroOther heroImage='../images/hero_about.jpg' heroTitle={pageNames[location.pathname] || 'Page Not Found'} branchDetails={brDetails} />
      }
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/article" element={<BlogArticle />} />
        <Route path="/contact" element={<Contact branchDetails={brDetails} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
      <Footer branchDetails={brDetails} />
    </div>
  );
}

export default App;
