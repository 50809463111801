import axios from "axios";
import { useEffect, useState } from "react";


export default function TabHomeServices() {
    const [serviceContent, setServiceContent] = useState();
    const getServicesCommon = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-services-common')
            .then(res => {
                setServiceContent(res.data);
            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getServicesCommon();
    }, [])

    const updateServiceTopic = (serviceID) => {
        const serviceTopic = document.querySelector('#serviceTopic_' + serviceID).value;
        if (serviceTopic.length === 0) {
            alert('Please enter a valid title to update.');
            return;
        } else if (serviceTopic.length > 25) {
            alert('Topic should be lover than 25 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update topic?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-services-common', { "serviceID": serviceID, "contentType": "topic", "contentData": serviceTopic })
            .then(res => {
                getServicesCommon();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }
    const updateServiceDescription = (serviceID) => {
        const serviceDescriptio = document.querySelector('#serviceDescription_' + serviceID).value;
        if (serviceDescriptio.length === 0) {
            alert('Please enter a valid description to update.');
            return;
        } else if (serviceDescriptio.length > 250) {
            alert('Topic should be lover than 250 characters');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update description?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-services-common', { "serviceID": serviceID, "contentType": "description", "contentData": serviceDescriptio })
            .then(res => {
                getServicesCommon();
                alert('Successfully updated!');
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const updateServiceImage = (serviceID, currentImage) => {
        const serviceImage = document.querySelector('#serviceImage_' + serviceID).files[0];
        if (serviceImage === undefined) {
            alert('Please select an image to update!');
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to update hero image?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        const formdata = new FormData();
        formdata.append('file', serviceImage);
        formdata.append('deleteFile', '.' + currentImage);

        axios.post(process.env.REACT_APP_API_LINK + '/upload-image', formdata, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                axios.post(process.env.REACT_APP_API_LINK + '/content/update-services-common', { "serviceID": serviceID, "contentType": "image", "contentData": '/images/' + serviceImage.name })
                    .then(res => {
                        getServicesCommon();
                        alert('Successfully updated!');
                        document.querySelector('.loading-gif-container').style.display = "none";
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            <div className="tab-home--container">
                <h3 className='home-topics'>Services Common</h3>
                {
                    serviceContent && serviceContent.map((data, index) => {
                        return (
                            <div className="inp-container" key={index}>
                                <div className="det-row" >
                                    <label style={{ fontWeight: "bold" }}>Service {index + 1}</label>
                                </div>
                                <div className="det-row" >
                                    <label>Image</label>
                                    <a href={process.env.REACT_APP_API_LINK + data.image} rel="noreferrer" target="_blank">Preview</a>
                                    <input id={'serviceImage_' + data.id} type="file" name='file' accept="image/png" />
                                    <button onClick={() => updateServiceImage(data.id, data.image)}>Update</button>
                                </div>
                                <div className="det-row" >
                                    <label>Topic</label>
                                    <input id={'serviceTopic_' + data.id} type="text" defaultValue={data.topic} />
                                    <button onClick={() => updateServiceTopic(data.id)}>Update</button>
                                </div>
                                <div className="det-row" >
                                    <label>Description</label>
                                    <textarea name="" id={'serviceDescription_' + data.id} cols="48" rows="5" defaultValue={data.description}></textarea>
                                    <button onClick={() => updateServiceDescription(data.id)}>Update</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}