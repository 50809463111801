import "./HeroTopSectionStyles.css"
import { useState } from "react";

export default function HeroTopSection(props) {
    const [showMail, setShowMail] = useState('contact-details');
    const [showCall, setShowCall] = useState('contact-details');

    const clickMail = e => {
        setShowCall('contact-details')
        showMail === 'contact-details' ? setShowMail('contact-details show-mail') : setShowMail('contact-details')
    }
    const clickCall = e => {
        setShowMail('contact-details')
        showCall === 'contact-details' ? setShowCall('contact-details show-call') : setShowCall('contact-details')
    }
    return (
        <>
            <div className="top">
                <div className="content">
                    <div className="company-logo">
                        <img src='../images/de_logo.png' alt="DE Lanka logo" />
                        <span>DE Lanka</span>
                    </div>
                    <div className="company-contacts">
                        <div className="contact-type mail">
                            <img src='../images/mail_logo.png' alt="" onClick={clickMail} />
                            <div className={showMail} id="mailAddress">
                                {
                                    props.branchDetails && props.branchDetails.map((data, index) => {
                                        return (
                                            <span key={index}>{data.name}  - {data.email}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="contact-type call">
                            <img src='../images/call_logo.png' alt="" onClick={clickCall} />
                            <div className={showCall} id="phoneNumbers">
                                {
                                    props.branchDetails && props.branchDetails.map((data, index) => {
                                        return (
                                            <span key={index}>{data.name}  - 0{data.phone1} / 0{data.phone2}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}