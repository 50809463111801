import { useEffect, useState } from 'react'
import './TabUsersStyles.css'
import axios from 'axios'

export default function TabUsers() {
    const [usersData, setUsersData] = useState();
    const getUsersData = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-users')
            .then(res => {
                setUsersData(res.data);
            })
            .catch(error => console.log(error))
    }
    useEffect(() => {
        getUsersData();
    }, [])

    const deleteAccount = (accID) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to delete this user account?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/delete-user', { "userID": accID })
            .then(res => {
                getUsersData();
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const changeUserType = (accID, currentType) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to change user type?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-user-type', { "userID": accID })
            .then(res => {
                getUsersData();
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    const changeUserStatus = (accID) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Do you want to change status?') === false) return;
        document.querySelector('.loading-gif-container').style.display = "flex";
        axios.post(process.env.REACT_APP_API_LINK + '/content/update-user-status', { "userID": accID })
            .then(res => {
                getUsersData();
                document.querySelector('.loading-gif-container').style.display = "none";
            })
            .catch(error => console.log(error))
    }

    return (
        <>
            <div className="user-table">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>User Type</th>
                            <th>Status</th>
                            <th>Registered Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usersData && usersData.map((data, index) => {
                                return (
                                    <tr key={data.id}>
                                        <td>{index + 1}</td>
                                        <td>{data.username}</td>
                                        <td>{data.user_type === 'admin' ? 'Admin':'Normal'} <i className="fas fa-exchange-alt" title={data.user_type === 'normal' ? 'Change to Admin' : 'Chnage to Normal'} onClick={() => changeUserType(data.id, data.user_type)}></i></td>
                                        <td>{data.approved === 'true' ? 'Approved':'Disapproved'} <i className="fas fa-exchange-alt" title={data.approved === 'true' ? 'Change to Disapproved' : 'Change to Approved'} onClick={() => changeUserStatus(data.id)}></i ></td>
                                        <td>{data.reg_date} <i className="fas fa-trash-alt" title='Delete account' onClick={() => deleteAccount(data.id)}></i></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}