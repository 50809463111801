import { useEffect, useState } from "react";
import "./WhyChooseStyles.css";
import axios from "axios";

export default function WhyChoose() {
    const CheckedIcon = "../images/checked_icon.svg"

    const [chooseContent, setChooseContent] = useState();
    const getWhyChoose = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-why-choose')
            .then(res => {
                setChooseContent(res.data[0]);
            })
            .catch(error => console.log(error));
    }
    useEffect(()=>{
        getWhyChoose()
    },[])

    // const chooseContent2 = {
    //     "image": "../images/home_why_choose_us.png",
    //     "imgInnerDet": [{ "sec1": "150", "sec2": "People Working" }, { "sec1": "9000+", "sec2": "Item Sold" }, { "sec1": "854", "sec2": "Happy Customers" }],
    //     "topic": "Few Reasons Why You Should Choose Us",
    //     "description": "Find out why DE Lanka is the preferred choice for all your motor and electrical needs. Our commitment to quality, reliability and exceptional customer service sets us apart. With competitive pricing and a team of experts, we ensure you get the best solutions to suit your needs.",
    //     "keypoints": [
    //         {
    //             "topic": "Quality and Reliability:",
    //             "desc": "We offer the highest quality motor and electrical products sourced from reputable manufacturers."
    //         },
    //         {
    //             "topic": "Exceptional Customer Service:",
    //             "desc": "Our dedicated team is committed to providing personalized support."
    //         },
    //         {
    //             "topic": "Warranty period:",
    //             "desc": "Our warranty period is unique in that we provide a one-year warranty period for both brand new and reconditioned items you receive."
    //         },
    //         {
    //             "topic": "Non-stop production:",
    //             "desc": "If your production stops due to an electric motor failure. Our company will not allow you to stop your products under any circumstances."
    //         },
    //         {
    //             "topic": "Expertise and Knowledge:",
    //             "desc": "Our expert team ensures you get expert advice and solutions."
    //         },
    //         {
    //             "topic": "Technical knowledge:",
    //             "desc": "Whenever you need technical knowledge from us, we are committed to providing you with genuine technical knowledge."
    //         },
    //         {
    //             "topic": "Tailored solutions:",
    //             "desc": "We customize our offerings to fit your unique needs."
    //         },
    //         {
    //             "topic": "Trust and Reputation:",
    //             "desc": "We have built a strong reputation for providing reliable and efficient products."
    //         },
    //     ]
    // }

    return (
        <>
            <div className="why-us-container">
                <div className="why-us-inner">
                    <div className="image-holder">
                        <img src={chooseContent && process.env.REACT_APP_API_LINK + chooseContent.image} alt="" />
                        <div className="inner-div">
                            <div className="counter-container">
                                {
                                    chooseContent && JSON.parse(chooseContent.imgInnerDet).map((det, index) => {
                                        return (
                                            <div className="counter-row" key={index}>
                                                <div className="numb">{det.sec1}</div>
                                                <div className="text">{det.sec2}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-holder">
                        <div className="sub-topic">
                            <img src='../images/home_service_logo_icon.svg' alt="" />
                            <p>Why Choose Us</p>
                        </div>
                        <h1 className="main-topic">{chooseContent && chooseContent.topic}</h1>
                        <p className="sub-description">{chooseContent && chooseContent.description}</p>
                        <div className="key-point-holder">
                            {
                                chooseContent && JSON.parse(chooseContent.keypoints).map((det, index) => {
                                    return (
                                        <div className="point-row" key={index}>
                                            <div className="checked">
                                                <img src={CheckedIcon} alt="" />
                                            </div>
                                            <div className="descrip">
                                                <h4>{det.topic}</h4>
                                                <p>{det.desc}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}