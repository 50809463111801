import './TabAboutStyles.css'
import TabAboutTeam from './TabAboutTeam'
import TabAboutVision from './TabAboutVision'

export default function TabAbout(){
    return(
        <>
            <TabAboutVision />
            <TabAboutTeam />
        </>
    )
}