import { useEffect, useState } from "react";
import "./AboutVisionStyles.css";
import axios from "axios";

export default function AboutVision() {
    const CheckedIcon = "../images/checked_icon.svg";
    const [visionObject, setVisionObject] = useState();
    const getVision = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-vision-mission')
            .then(res => {
                const obj = {
                    "vision": {
                        "description": res.data[0].description,
                        "keypoints": res.data[0].keypoints
                    },
                    "mission": {
                        "description": res.data[1].description,
                        "keypoints": res.data[1].keypoints
                    }
                }
                setVisionObject(obj);
            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getVision();
    }, [])
    // const visionObject2 = {
    //     "vision": {
    //         "description": "Being the Sri Lankan pioneer in providing all the electric motors, machinery, electrical and electronic components needed to create and maintain new industries for the improvement of the Sri Lankan industrial sector to the foreign market by producing machinery, industrial and agricultural products suitable for local consumption and export to foreign countries. Market entry.",
    //         "keypoints": [ { "topic": "Sri Lankan industrial :", "desc": "Improving the Sri Lankan industrial sector" }, { "topic": "To establish new industries :", "desc": "Supplying all electric motors, machinery, electrical and electronic components required for setting up and maintaining new industries." }, { "topic": "For Domestic Consumption and Export :", "desc": "Being the Sri Lankan pioneer by providing machinery suitable for local consumption and export." }, { "topic": "Access to foreign markets :", "desc": "Access to foreign markets by producing industrial and agricultural products." } ]
    //     },
    //     "mission": {
    //         "description": "By operating as the most reliable supplier of electrical and electronic equipment required for all industries, we are developing the knowledge and training of all the operators of the DE Lanka family, raising their quality of life and working to increase their quality of life and more than 30,000 of the nearly 64,000 small and medium-scale industries in Sri Lanka. By making them permanent buyers and providing special service support for them, targeting the area where there are many industries through 05 grant branches. Marketing expansion. Helping to choose the products according to the needs of the industrialists under more quality and convenient prices Providing liaison assistance to manufacturers to market their manufactured machinery, services or goods.",
    //         "keypoints": [ { "topic": "Acting as a trusted supplier :", "desc": "Acting as the most reliable supplier of electrical and electronic components for all industries." }, { "topic": "Provide efficient service :", "desc": "To provide a more efficient service from the organization by cultivating the knowledge and training of all the operators of our DE Lanka family and thereby raising their quality of life." }, { "topic": "Creating more industries :", "desc": "Making more than 30,000 out of nearly 64,000 small and medium scale industries in Sri Lanka as our permanent buyers and providing special service support for them." }, { "topic": "Marketing expansio :", "desc": "Through 05 grant branches targeting the area where there are many industries. Marketing expansion." }, { "topic": "Providing technical support :", "desc": "Best quality and timely delivery at affordable prices. Helping to choose products according to the needs of the workers and providing technical support." }, {"topic": "Warranty Service :", "desc": "Timely provision of after sales warranty service." }, { "topic": "Introducing  :", "desc": "Introducing modern technological machinery and devices." }, { "topic": "Providing knowledge and equipment :", "desc": "Encouraging inventors to try their hands by providing them with knowledge and equipment at the lowest possible price." }, { "topic": "Provide affiliate marketing support :", "desc": "Providing liaison assistance to manufacturers to market their manufactured machinery, services or goods." }]
    //     },
    // }
    return (
        <>
            <div className="vision-container">
                <div className="vision-inner">
                    <div className="side-coontent">
                        <h1>Our Vision</h1>
                        <p>{visionObject && visionObject.vision.description}</p>
                        <div className="key-point-holder">
                            {
                                visionObject && JSON.parse(visionObject.vision.keypoints).map((value, index) => {
                                    return (
                                        <div className="point-row" key={index}>
                                            <div className="checked">
                                                <img src={CheckedIcon} alt="" />
                                            </div>
                                            <div className="descrip">
                                                <h4>{value.topic}</h4>
                                                <p>{value.desc}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="side-container">
                        <img src="images/about_vission_icon.svg" alt="" />
                    </div>
                </div>
            </div>
            <div className="vision-container white-back">
                <div className="vision-inner">
                    <div className="side-container">
                        <img src="images/about_mission_icon.svg" alt="" />
                    </div>
                    <div className="side-coontent">
                        <h1>Our Mission</h1>
                        <p>{visionObject && visionObject.mission.description}</p>
                        <div className="key-point-holder">
                            {
                                visionObject && JSON.parse(visionObject.mission.keypoints).map((value, index) => {
                                    return (
                                        <div className="point-row" key={index}>
                                            <div className="checked">
                                                <img src={CheckedIcon} alt="" />
                                            </div>
                                            <div className="descrip">
                                                <h4>{value.topic}</h4>
                                                <p>{value.desc}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}