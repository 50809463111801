import "./ContactDetailsStyles.css"

export default function ContactDetails(props) {
    
    return (
        <>
            <div className="conatct-det-container">
                <div className="conatct-det-inner">
                    <div className="sub-topic">
                        <img src='../images/home_service_logo_icon.svg' alt="" />
                        <p>Quick Contact</p>
                    </div>
                    <h1 className="main-topic">Contact Details</h1>
                    <div className="branch-container">
                        {
                            props.branchDetails && props.branchDetails.map((data) => {
                                return (
                                    <div className="branch" key={data.brID}>
                                        <h4>{data.name} Branch</h4>
                                        <h5>Address:</h5>
                                        <a href="/contact">{data.address}</a>
                                        <h5>Phone:</h5>
                                        <a href={"tel:+94" + data.phone2}>0{data.phone1} / 0{data.phone2}</a>
                                        <h5>Mail:</h5>
                                        <a href={"mailto:" + data.email}>{data.email}</a>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}