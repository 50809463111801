import { useEffect, useState } from "react";
import "./AboutSimpleStyles.css";
import axios from "axios";

export default function AboutSimple(props) {
    const [aboutContent, setAboutContent] = useState();
    const getHomeAbout = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-home-about')
        .then(res => {
            setAboutContent(res.data);
        })
        .catch(error => console.log(error));
    }
    useEffect(()=>{
        getHomeAbout();
    },[]);

    return (
        <>
            <div className="about-container">
                <div className={props.ifReverse ? 'about-inner reverse' : 'about-inner'}>
                    <div className="image-container">
                        <img src={aboutContent && process.env.REACT_APP_API_LINK + aboutContent.image} alt="" />
                    </div>
                    <div className="text-container">
                        <div className="sub-header">
                            <img src='../images/about_sub_header_icon.svg' alt="" />
                            <p>Welcome to DE Lanka</p>
                        </div>
                        <h1 className="main-header">{aboutContent && aboutContent.topic}</h1>
                        <p className="about-description">{aboutContent && aboutContent.description}</p>
                        <div className="key-points">
                            {
                                aboutContent && aboutContent.keypoints.split(',').map((point, index) => {
                                    return (
                                        <div className="point" key={index}>
                                            <img src='../images/checked_icon.svg' alt="" />
                                            <p>{point}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}