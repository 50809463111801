import { useEffect, useState } from "react";
import "./AboutTeamStyles.css";
import axios from "axios";

export default function AboutTeam() {
    const [teamMembers, setTeamMembers] = useState();
    const getTeamMembers = () => {
        axios.get(process.env.REACT_APP_API_LINK + '/content/get-about-team')
            .then(res => {
                setTeamMembers(res.data.filter((value)=> (value.image !== null && value.image.length > 0) && (value.name !== null && value.name.length > 0) && (value.designation !== null && value.designation.length > 0)));
            })
            .catch(error => console.log(error));
    }
    useEffect(() => {
        getTeamMembers();
    }, [])

    // const teamMembers2 = [
    //     {
    //         "image": "../images/about_team_1.jpg",
    //         "name": "I.D.N. THAKASHILA PATHIRANA",
    //         "designation": "Weweldeniya Branch Manager",
    //     },
    //     {
    //         "image": "../images/about_team_2.jpg",
    //         "name": "M.M.T. MADUSHANKA MEGHASOORIYA",
    //         "designation": "Battuluoya Branch Manager",
    //     },
    //     {
    //         "image": "../images/about_team_3.jpg",
    //         "name": "K.A.N. MAHESHIKA NAYANANANDHA",
    //         "designation": "Dodangoda Branch Manager",
    //     }
    // ]
    return (
        <>
            <div className="team-container">
                <div className="team-inner">
                    <div className="sub-topic">
                        <img src='../images/home_service_logo_icon.svg' alt="" />
                        <p>Our Team</p>
                    </div>
                    <h1 className="main-topic">Meet Our Experts</h1>
                    <p className="sub-description">Meet our team of seasoned experts, dedicated to providing you with exceptional knowledge and industry expertise.</p>
                    <div className="members-container">
                        {
                            teamMembers && teamMembers.map((det, index) => {
                                return (
                                    <div className="member" key={index}>
                                        <img src={process.env.REACT_APP_API_LINK + det.image} alt="" />
                                        <div className="details">
                                            <h4>{det.name}</h4>
                                            <p>{det.designation}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}